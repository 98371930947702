// Move to Vue 3's composition API when available
export default {
  computed: {
    interiorRoutes () {
      const topLevelRoute = this.$route.matched[0]
      return this.$router.options.routes.find(route => route.name === topLevelRoute.name).children
    },
    interiorNavigationItems () {
      return this.interiorRoutes.map(route => ({
        id: route.name,
        text: route.meta.title,
        href: this.$router.resolve({ name: route.name }).href,
        active: route.name === this.$route.name,
      }))
    },
  },
}
