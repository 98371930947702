<template>
  <div class="quick-link__container">
    <div class="quick-link__link">
      <a
        class="quick-link__name-link"
        :href="$router.resolve({
          name: page
        }).href"
        @click="setUserFilters(filtersSelectedView)"
      >
        {{ name }}
      </a>
    </div>
    <div class="quick-link__actions">
      <mds-button
        :id="getEditQuickLinkBtnId"
        variation="icon-only"
        size="small"
        icon="pencil"
        type="button"
        @click="triggerEditQuickLinkDialog()"
      >
        Edit
      </mds-button>
      <mds-button
        :id="getRemoveQuickLinkBtnId"
        variation="icon-only"
        size="small"
        icon="trash"
        type="button"
        @click="triggerRemoveQuickLinkDialog()"
      >
        Delete
      </mds-button>
    </div>
  </div>
</template>

<script>
import { MdsButton } from '@mds/button'
import { camelCase } from 'lodash'
import { PAGE_FILTERS_MAP } from '../../../shared/constants/filters'
import changeViewMixin from '../../mixins/change-view-mixin'

export default {
  components: {
    MdsButton,
  },
  mixins: [
    changeViewMixin,
  ],
  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    page: {
      type: String,
      default: '',
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    filtersSelectedView: {
      type: String,
      default: '',
    },
  },
  computed: {
    camelCasedPageName () {
      return camelCase(this.page)
    },
    getEditQuickLinkBtnId () {
      return `edit-quick-link-btn-${this.id}`
    },
    getRemoveQuickLinkBtnId () {
      return `remove-quick-link-btn-${this.id}`
    },
  },
  methods: {
    clearPreviousFilters () {
      return PAGE_FILTERS_MAP[this.camelCasedPageName].map((filter) => {
        const currentFilter = `${filter}___${this.camelCasedPageName}`
        if (sessionStorage.getItem(currentFilter) !== null) {
          sessionStorage.removeItem(currentFilter)
        }
      })
    },
    setUserFilters (filtersSelectedView) {
      // Reset filters to default based on each page
      this.clearPreviousFilters()
      // Switch selected view depending on filters saved view
      this.changeView(filtersSelectedView)

      return this.data.map((filter) => {
        const [filterData] = Object.entries(filter)
        const [key, value] = filterData
        return sessionStorage.setItem(`${key}`, JSON.stringify(value))
      })
    },
    triggerEditQuickLinkDialog () {
      this.$emit('trigger-edit-quick-link-dialog', { linkId: this.id, quickLinkName: this.name })
    },
    triggerRemoveQuickLinkDialog () {
      this.$emit('trigger-remove-quick-link-dialog', { linkId: this.id, quickLinkName: this.name })
    },
  },
}
</script>

<style lang="scss" scoped>
  .quick-link__name-link {
    @include mds-link();
  }

  .quick-link__container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0;
    transition: 0.2s;

    &:focus, &:hover {
      background-color: $mds-color-neutral-90;
      padding: 3px 5px;
      border-radius: 2px;
    }

    .quick-link__link {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 260px;
    }
    .quick-link__actions {
      width: 45px;
      display: flex;
      justify-content: space-between;
    }
  }
</style>
