<template>
  <mds-section
    :heading-level="4"
    :size="6"
    :title="dialogTitle"
    class="dashboard-add-providers-dialog__section"
    border="primary"
    border-position="bottom"
    bold
  >
    <template>
      <div
        class="dashboard-add-providers-dialog__description"
      >
        {{ dialogDescription }}
      </div>
      <mds-form
        class="dashboard-add-providers-dialog__form"
      >
        <mds-search-field
          v-model="searchedProvider"
          variation="input"
          label="Search Provider"
        />
        <mds-fieldset
          class="dashboard-add-providers-dialog__provider-list"
        >
          <mds-radio-button
            v-for="provider in filteredSearchedProviders"
            :key="provider.id"
            v-model="selectedProvider"
            class="dashboard-add-providers-dialog__provider-item"
            :name="provider.value.providerCode"
            :value="provider.value"
          >
            {{ provider.value.providerCode }}
          </mds-radio-button>
        </mds-fieldset>
      </mds-form>
    </template>
  </mds-section>
</template>

<script>
import MdsSection from '@mds/section'
import MdsForm from '@mds/form'
import MdsFieldset from '@mds/fieldset'
import MdsRadioButton from '@mds/radio-button'
import MdsSearchField from '@mds/search-field'
import { ADD_PROVIDER_DIALOG_EVENT_EMITTER } from '../../../shared/constants/event-emitters'

const INITIAL_SEARCHED_PROVIDER = ''
const INITIAL_SELECTED_PROVIDER = ''

export default {
  components: {
    MdsSection,
    MdsForm,
    MdsFieldset,
    MdsRadioButton,
    MdsSearchField,
  },
  props: {
    dialogTitle: {
      type: String,
      required: true,
    },
    dialogDescription: {
      type: String,
      required: true,
    },
    providerList: {
      type: Array,
      default: () => [],
    },
    userProviderList: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      searchedProvider: INITIAL_SEARCHED_PROVIDER,
      selectedProvider: INITIAL_SELECTED_PROVIDER,
    }
  },
  computed: {
    removeCurrentUserProviders () {
      // Remove current user providers from complete providerList array
      return this.providerList.filter(({ value }) => (
        !this.userProviderList.some(({ providerCode }) => value.providerCode === providerCode))
      )
    },
    filteredSearchedProviders () {
      return this.removeCurrentUserProviders.filter((provider) => (
        provider.value.providerCode.toLowerCase().includes(this.searchedProvider.toLowerCase())
      ))
    },
  },
  watch: {
    selectedProvider: {
      handler (newVal) {
        if (newVal.length !== 1) {
          this.currentProviderId(newVal)
        }
      },
      deep: true,
    },
  },
  methods: {
    currentProviderId (newSelectedProvider) {
      const { providerId, providerCode } = newSelectedProvider
      this.$emit(ADD_PROVIDER_DIALOG_EVENT_EMITTER, { providerId, providerCode })
    },
  },
}
</script>

<style lang="scss">
  .mds-section___radar.dashboard-add-providers-dialog__section {
    .mds-section--primary___radar.mds-section--border-bottom___radar {
      .mds-section__title___radar {
        padding-top: 0;
      }
    }
  }
  .dashboard-add-providers-dialog__description {
    padding: $mds-space-1-x;
  }
  .dashboard-add-providers-dialog__form {
    .dashboard-add-providers-dialog__provider-list {
      margin: $mds-space-1-and-a-half-x 0;
      max-height: 200px;
      border: 1px solid $mds-color-neutral-90;
      border-radius: $mds-space-three-quarter-x;
      overflow: scroll;
      .dashboard-add-providers-dialog__provider-item {
        margin-bottom: 0;
        padding: $mds-space-1-x $mds-space-1-and-a-half-x;
        background-color: $mds-background-color-light-gray;
        border-bottom: 1px solid $mds-color-neutral-90;
      }
    }
  }
</style>
