import RoleAccess from '../services/role-access-service'
import { SWITCH_VIEW_EVENT_EMITTER } from '../../shared/constants/event-emitters'
import { RadarViews } from '../../shared/constants/radar-views'

export default {
  data () {
    return {
      VIEWS: RadarViews,
    }
  },
  methods: {
    changeView (selectedView) {
      const liveFilterAndTestSelectedView = (selectedView === this.VIEWS.LIVE) && (RoleAccess.selectedView === this.VIEWS.TEST)
      const testFilterAndLiveSelectedView = (selectedView === this.VIEWS.TEST) && (RoleAccess.selectedView === this.VIEWS.LIVE)
      // Switch selected view depending on filters saved view
      if (RoleAccess.selectedView !== this.VIEWS.ALL) {
        if (liveFilterAndTestSelectedView) {
          this.$root.$emit(SWITCH_VIEW_EVENT_EMITTER, this.VIEWS.LIVE)
        } else if (testFilterAndLiveSelectedView) {
          this.$root.$emit(SWITCH_VIEW_EVENT_EMITTER, this.VIEWS.TEST)
        } else if (selectedView === this.VIEWS.ALL) {
          this.$root.$emit(SWITCH_VIEW_EVENT_EMITTER, this.VIEWS.ALL)
        }
      }
    },
  },
}
