<template>
  <div class="watch-list__container">
    <div class="watch-list__title-container">
      <div class="watch-list__title">
        {{ provider }}
        <mds-icon
          v-if="tracked"
          name="eye"
        />
      </div>
      <div class="watch-list__actions">
        <mds-button
          :id="getPopoverButtonId"
          variation="icon-only"
          size="small"
          icon="caret-circle-down"
          type="button"
          @click="toggleWatchListPopover()"
        >
          Menu
        </mds-button>
      </div>
    </div>
    <div
      v-if="note"
      class="watch-list__content"
    >
      <div class="watch-list__read-description">
        <p>
          {{ truncateDescriptionNote }}
        </p>
        <mds-button
          v-if="getWordsLength"
          variation="flat"
          class="watch-list__read-more-button"
          @click="showCompleteNote"
        >
          {{ readMoreButtonName }}
        </mds-button>
      </div>
    </div>
  </div>
</template>

<script>
import { MdsButton } from '@mds/button'
import MdsIcon from '@mds/icon'
import { truncate } from 'lodash'
import { WATCH_LIST_POPOVER_EVENT_EMITTER } from '../../../shared/constants/event-emitters'

export default {
  components: {
    MdsButton,
    MdsIcon,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    provider: {
      type: String,
      default: '',
    },
    note: {
      type: String,
      default: '',
    },
    tracked: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      showButton: true,
    }
  },
  computed: {
    getWordsLength () {
      return this.note.split(' ').length > 20
    },
    truncateDescriptionNote () {
      return this.showButton ? this.truncateNote(this.note) : this.note
    },
    readMoreButtonName () {
      return this.showButton && this.note ? 'Read More' : 'Read Less'
    },
    getPopoverButtonId () {
      return `trigger-btn-${this.id}`
    },
  },
  methods: {
    toggleWatchListPopover () {
      const triggerPopover = this.getPopoverButtonId
      const id = this.id
      const isTracked = this.tracked
      const providerName = this.provider
      const providerNotes = this.note
      this.$emit(WATCH_LIST_POPOVER_EVENT_EMITTER, { triggerPopover, id, providerName, providerNotes, isTracked })
    },
    showCompleteNote () {
      this.showButton = !this.showButton
    },
    truncateNote (note) {
      return truncate(note, {
        length: 178,
        omission: '',
        separator: /,?\.* +/,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.watch-list__container{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  padding: 0;
    &:nth-last-child(n+2){
        border-bottom: 1px solid $mds-color-neutral-80;
    }

.watch-list__title{
  @include mds-body-text-l($bold: true);
  display: flex;
  align-items: center;
  .mds-icon___radar{
      margin-left: $mds-space-1-x;
  }
}

.watch-list__title-container{
    display: flex;
    width: 100%;
    padding: $mds-space-2-x 0;
    justify-content: space-between;
}
.watch-list__actions{
    display: flex;
    justify-content: space-between;
}

.watch-list__content{
    width: 100%;
}

.watch-list__read-description{
    margin-bottom: $mds-space-2-x;
    p{
        display: inline;
        margin: 0;
    }
}

.watch-list__read-more-button{
    color: $mds-interactive-color-primary-default;
    display: inline;
    padding: 0;
}
}
</style>
