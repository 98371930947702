<template>
  <content-template :navigation="interiorNavigationItems">
    <router-view />
  </content-template>
</template>

<script>
import ContentTemplate from '../../components/ContentTemplate/ContentTemplate'
import interiorNavigationMixin from '../../mixins/interior-navigation-mixin'

export default {
  components: {
    ContentTemplate,
  },
  mixins: [
    interiorNavigationMixin,
  ],
}
</script>
