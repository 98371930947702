<template>
  <content-template
    :no-padding="true"
  >
    <content-container
      :no-margin="true"
    >
      <div
        class="dashboard__content"
      >
        <div class="dashboard__tables">
          <!-- Begin - My Details table -->
          <mds-section
            :collapsible="true"
            :heading-level="4"
            :size="6"
            title="My Details"
            class="dashboard-section"
            border="secondary"
            expanded
            bold
          >
            <fade-transition>
              <mds-loader v-if="loadingMyDetails" />
              <lazy-table
                v-else-if="tableDataValidation"
                :offset="500"
                :header-configs="tableHeaders"
                :row-data="tableDataWithPagination"
                :fixed-first-col="true"
                :pagination-config="myDetailsPaginationConfig"
                pagination="below"
                @mds-data-table-page-change="handleTablePageChange"
                @toggle-action-popover="toggleActionButtonPopover($event)"
              />
              <mds-empty-state
                v-else-if="emptyStateValidation"
                title="No Details saved."
                message="Save Plan or File Details to access them directly from the Dashboard."
                size="large"
                class="dashboard__empty"
              >
                <template v-slot:mds-empty-state-icon>
                  <img :src="`${publicPath}images/icons/empty-search.svg`">
                </template>
              </mds-empty-state>
            </fade-transition>
          </mds-section>
          <!-- End - My Details table  -->
          <!-- Begin - Terminated Processes -->
          <mds-section
            :heading-level="4"
            :size="6"
            title="8 Terminated Processes (Last 14 Days)"
            class="dashboard-section"
            border="secondary"
            bold
          >
            <template v-slot:mds-section-actions>
              <mds-button
                variation="secondary"
                size="small"
                disabled
              >
                View
              </mds-button>
            </template>
          </mds-section>
          <!-- End - Terminated Processes  -->
          <!-- Begin - Schema Failures -->
          <mds-section
            :heading-level="4"
            :size="6"
            title="23 Schema Failures (Last 14 Days)"
            class="dashboard-section"
            border="secondary"
            bold
          >
            <template v-slot:mds-section-actions>
              <mds-button
                variation="secondary"
                size="small"
                disabled
              >
                View
              </mds-button>
            </template>
          </mds-section>
          <!-- End - Schema Failures  -->
        </div>
        <div class="dashboard__quick-links-watch">
          <!-- QuickLink and Watch List Section -->
          <!-- QuickLinks Container -->
          <div class="dashboard__quick-links">
            <mds-section
              border="none"
              title="Quick Links"
              :size="7"
              :collapsible="true"
              expanded
            >
              <fade-transition>
                <mds-loader v-if="loadingQuickLinks" />
                <content-container
                  v-else
                  :no-margin="true"
                >
                  <quick-link
                    v-for="link in quickLinksData"
                    :id="link.linkId"
                    :key="link.linkId"
                    :name="link.quickLinkName"
                    :data="link.quickLinkData"
                    :page="link.page"
                    :is-active="link.isActive"
                    :filters-selected-view="link.selectedView"
                    @trigger-edit-quick-link-dialog="triggerEditQuickLinkDialog($event)"
                    @trigger-remove-quick-link-dialog="triggerRemoveQuickLinkDialog($event)"
                  />
                </content-container>
              </fade-transition>
            </mds-section>
          </div>
          <!-- Watch List Container -->
          <div class="dashboard__watch-list">
            <mds-section
              border="none"
              title="Watch List"
              :size="7"
              :collapsible="true"
              expanded
            >
              <template v-slot:mds-section-actions>
                <mds-button
                  variation="flat"
                  icon="plus"
                  flat-button-icon-size="small"
                  size="small"
                  type="button"
                  class="dashboard__btn-provider"
                  @click="triggerModalAddProviderDialog"
                >
                  Add Provider
                </mds-button>
              </template>
              <fade-transition>
                <mds-loader v-if="loadingWatchList" />
                <content-container
                  v-else
                  :no-margin="true"
                >
                  <watch-list
                    v-for="provider in userProviderData"
                    :id="provider.providerId"
                    :key="provider.providerId"
                    :provider="provider.providerCode"
                    :note="provider.providerNotes"
                    :tracked="provider.isTracked"
                    @toggle-watch-list-popover="toggleWatchListPopover($event)"
                  />
                </content-container>
              </fade-transition>
            </mds-section>
          </div>
        </div>
      </div>
      <!--
      POPOVER TABLE
      One single popover created outside MDS data-table to avoid create dynamically multiple popover instances.
      This takes automatically its position based on their respective button ID, which is retrieved from triggerPopoverTable data prop.
      -->
      <mds-popover
        ref="popoverRef"
        :triggered-by="triggerPopoverTable"
        position="bottom-right"
        class="dashboard__popover"
        width="200px"
        title-hidden
      >
        <mds-button
          variation="flat"
          type="button"
          @click="changeViewAndRedirect"
        >
          View Details
        </mds-button>
        <mds-button
          variation="flat"
          type="button"
          @click="triggerModalEditMyDetailDialog"
        >
          Edit Name
        </mds-button>
        <mds-button
          variation="flat"
          type="button"
          @click="triggerModalDeleteMyDetailDialog"
        >
          Remove
        </mds-button>
      </mds-popover>
      <!--
      POPOVER WATCH LIST
      -->
      <mds-popover
        ref="popoverWatchListRef"
        :triggered-by="triggerPopoverWatchList"
        position="bottom-left"
        class="dashboard__popover"
        width="200px"
        title-hidden
      >
        <mds-button
          variation="flat"
          type="button"
          @click="triggerAddProviderNotesDialog()"
        >
          {{ addUserProviderNotesLabel }}
        </mds-button>
        <mds-button
          variation="flat"
          type="button"
          @click="trackUserProvider(activeWatchListItem)"
        >
          {{ trackUserProviderName }}
        </mds-button>
        <mds-button
          variation="flat"
          type="button"
          @click="triggerRemoveProviderDialog()"
        >
          Remove Provider
        </mds-button>
      </mds-popover>
      <!--
      DASHBOARD DIALOG
      -->
      <mds-dialog
        v-model="toggleDialog"
        action-required
        :width="dialogWidth"
      >
        <dashboard-dialog-add-provider
          v-if="addProviderDialogOpen"
          dialog-title="Add Provider"
          dialog-description="This provider will appear in the Watch List section of the Dashboard"
          :provider-list="providerList"
          :user-provider-list="userProviderData"
          @add-watch-list-provider="getSelectedProviderId($event)"
        />
        <dashboard-dialog-edit-content
          v-if="editMyDetailsDialogOpen || editQuickLinkDialogOpen || editProviderNotesDialogOpen"
          v-model="inputText"
          :dialog-title="titleEditDialog"
          :dialog-description="descriptionEditDialog"
          :is-add-provider-notes="editProviderNotesDialogOpen"
        />
        <dashboard-dialog-delete-content
          v-if="removeQuickLinkDialogOpen || removeProviderDialogOpen || removeDetailDialogOpen"
          :dialog-title="activeRemoveTitle"
        />
        <template v-slot:mds-dialog-actions-right>
          <dashboard-dialog-buttons
            :dialog-cancel-button-text="dialogCancelButtonText"
            :dialog-action-button-text="dialogActionButtonText"
            :disable-dialog-active-btn="disableDialogActiveBtn"
            @close-dialog="closeDialog"
            @action-dialog-button="actionDialogButton"
          />
        </template>
      </mds-dialog>
    </content-container>
  </content-template>
</template>

<script>
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import ContentTemplate from '../../components/ContentTemplate/ContentTemplate'
import DashboardDialogDeleteContent from '../../components/DashboardDialogContent/DashboardDialogDeleteContent'
import DashboardDialogEditContent from '../../components/DashboardDialogContent/DashboardDialogEditContent'
import DashboardDialogAddProvider from '../../components/DashboardDialogContent/DashboardDialogAddProvider'
import DashboardDialogButtons from '../../components/DashboardDialogContent/DashboardDialogButtons'
import LazyTable from '../../components/LazyTable/LazyTable'
import MdsPopover from '../../components/Popover/Popover'
import FadeTransition from '../../components/Transitions/FadeTransition'
import { MdsButton } from '@mds/button'
import MdsLoader from '@mds/loader'
import MdsSection from '@mds/section'
import MdsDialog from '@mds/dialog'
import MdsEmptyState from '@mds/empty-state'
import QuickLink from '../../components/QuickLink/QuickLink'
import WatchList from '../../components/WatchList/WatchList'
import { CACHE_AND_NETWORK, CACHE_FIRST, NETWORK_ONLY, NO_CACHE } from '../../graqhql/fetch-policies'
import changeViewMixin from '../../mixins/change-view-mixin'
import RoleAccess from '../../services/role-access-service'
import {
  GET_PROVIDERS,
  GET_DASHBOARD_QUICK_LINKS,
  GET_DASHBOARD_PROVIDERS,
  GET_DASHBOARD_USER_ID,
  GET_DASHBOARD_MY_DETAILS,
  GET_DASHBOARD_WATCH_LIST_PROVIDER_ID,
} from '../../graqhql/queries'
import {
  CREATE_DASHBOARD_USER_PROVIDER,
  UPDATE_DASHBOARD_USER_PROVIDER_STATUS,
  UPDATE_DASHBOARD_USER_PROVIDER_NOTES,
  UPDATE_DASHBOARD_USER_PROVIDER_IS_ACTIVE,
  UPDATE_DASHBOARD_QUICK_LINK_NAME,
  UPDATE_DASHBOARD_QUICK_LINK_IS_ACTIVE,
  UPDATE_DASHBOARD_MY_DETAILS_NAME,
  DELETE_DASHBOARD_MY_DETAILS,
} from '../../graqhql/mutations'

const INITIAL_USER = null
const INITIAL_USER_ID = null
const INITIAL_SELECTED_PROVIDER_ID = ''
const EMPTY_ACTIVE_QUICK_LINK_ITEM = {}
const EMPTY_ACTIVE_WATCH_LIST_ITEM = {}
const EMPTY_INPUT_TEXT = ''
const MY_DETAILS_INITIAL_STATE = null
const INITIAL_ACTIVE_CELL_DATA = {
  rowData: {
    fileType: '',
    provider: '',
  },
}

const TABLE_HEADERS = [
  {
    fieldName: 'action',
    text: 'Action',
    width: '50px',
  },
  {
    fieldName: 'name',
    text: 'Name',
    width: '130px',
  },
  {
    fieldName: 'provider',
    text: 'Provider',
    width: '130px',
  },
  {
    fieldName: 'processType',
    text: 'Process Type',
    width: '100px',
  },
  {
    fieldName: 'fiduciary',
    text: 'Fiduciary',
    width: '130px',
  },
  {
    fieldName: 'details',
    text: 'Details',
    width: '130px',
  },
]

// Pagination constants
const NUMBER_OF_ROWS_PER_PAGE_DEFAULT = 10
const NUMBER_OF_ROWS_PER_PAGE_OPTIONS = [10, 20, 40, 80]

export default {
  components: {
    ContentContainer,
    ContentTemplate,
    DashboardDialogDeleteContent,
    DashboardDialogEditContent,
    DashboardDialogAddProvider,
    DashboardDialogButtons,
    LazyTable,
    MdsPopover,
    FadeTransition,
    MdsSection,
    MdsDialog,
    MdsButton,
    QuickLink,
    WatchList,
    MdsLoader,
    MdsEmptyState,
  },
  mixins: [
    changeViewMixin,
  ],
  apollo: {
    providers: {
      query: GET_PROVIDERS,
      variables () {
        return {
          selectedView: this.selectedView,
        }
      },
      error (error) {
        // Error handling for unauthorized users
        const authErrorMessage = error?.message || error?.graphQLErrors[0].message
        this.$notifications.error({ text: authErrorMessage, tinted: true })
      },
      fetchPolicy: CACHE_FIRST,
    },
    userId: {
      query: GET_DASHBOARD_USER_ID,
      fetchPolicy: CACHE_FIRST,
    },
    userProviders: {
      query: GET_DASHBOARD_PROVIDERS,
      variables () {
        return {
          userId: this.userId.id,
        }
      },
      skip () {
        // if the user is not on apolloDB
        return this.userId === INITIAL_USER_ID
      },
    },
    quickLinks: {
      query: GET_DASHBOARD_QUICK_LINKS,
      variables () {
        return {
          userId: this.userId?.id,
        }
      },
      skip () {
        // if the user is not on apolloDB
        return this.userId === INITIAL_USER_ID
      },
      fetchPolicy: CACHE_AND_NETWORK,
    },
    myDetails: {
      query: GET_DASHBOARD_MY_DETAILS,
      variables () {
        return {
          userId: this.userId?.id,
        }
      },
      skip () {
        // if the user is not on apolloDB
        return this.userId === INITIAL_USER_ID
      },
      error () {
        this.errorLoadingData = true
        this.$notifications.error({ text: 'There was an issue fetching my Details data. Please try again.', tinted: true, persistent: true })
      },
      fetchPolicy: NETWORK_ONLY,
    },
  },
  data () {
    return {
      user: INITIAL_USER,
      userId: INITIAL_USER_ID,
      publicPath: process.env.BASE_URL,
      providers: [],
      userProviders: [],
      quickLinks: [],
      myDetails: MY_DETAILS_INITIAL_STATE,
      toggleDialog: false,
      addProviderDialogOpen: false,
      editQuickLinkDialogOpen: false,
      editProviderNotesDialogOpen: false,
      editMyDetailsDialogOpen: false,
      removeQuickLinkDialogOpen: false,
      removeProviderDialogOpen: false,
      removeDetailDialogOpen: false,
      currentSelectedProviderId: INITIAL_SELECTED_PROVIDER_ID,
      tableHeaders: TABLE_HEADERS,
      activeCellData: INITIAL_ACTIVE_CELL_DATA,
      activeQuickLinkItem: EMPTY_ACTIVE_QUICK_LINK_ITEM,
      activeWatchListItem: EMPTY_ACTIVE_WATCH_LIST_ITEM,
      inputText: EMPTY_INPUT_TEXT,
      triggerPopoverTable: '',
      triggerPopoverWatchList: '',
      tableDataPaginationData: {
        pageSize: NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: 1,
        firstItem: 1,
      },
      updateUserProviderStatus: null,
      errorLoadingData: false,
      selectedView: RoleAccess.selectedView,
    }
  },
  computed: {
    loadingQuickLinks () {
      return this.$apollo.queries.quickLinks.loading
    },
    loadingMyDetails () {
      return this.$apollo.queries.myDetails.loading
    },
    loadingWatchList () {
      return this.$apollo.queries.userProviders.loading
    },
    isEditDialogOpen () {
      return this.editQuickLinkDialogOpen || this.editProviderNotesDialogOpen || this.editMyDetailsDialogOpen
    },
    titleEditDialog () {
      return this.editMyDetailsDialogOpen
        ? 'Edit Details Page Name' : this.editProviderNotesDialogOpen
          ? `Edit Notes for ${this.activeWatchListItem.providerName}` : this.activeQuickLinkNameTitle
    },
    descriptionEditDialog () {
      return this.editMyDetailsDialogOpen
        ? 'Save this Details Page to add it to the My Details section of the Dashboard.' : this.editProviderNotesDialogOpen
          ? 'Edit the text below to add a personal note for this Provider. This note will only be visible by you.'
          : 'Rename this set of filters to update it in the Quick Links section of the Dashboard.'
    },
    getActiveDetail () {
      return this.myDetails?.find((detail) => detail.id === this.activeCellData.rowData.id) || null
    },
    dialogWidth () {
      return this.isEditDialogOpen || this.addProviderDialogOpen ? '500px' : '350px'
    },
    dialogCancelButtonText () {
      return this.isEditDialogOpen ? 'Cancel' : 'No'
    },
    dialogActionButtonText () {
      return this.editQuickLinkDialogOpen || this.editMyDetailsDialogOpen
        ? 'Save' : this.editProviderNotesDialogOpen
          ? 'Add Notes' : this.addProviderDialogOpen
            ? 'Add Provider' : 'Yes'
    },
    providerList () {
      return [...this.providers.map(({ id, code }) => ({ id, value: { providerId: id, providerCode: code } }))]
    },
    tableData () {
      if (this.myDetails !== MY_DETAILS_INITIAL_STATE) {
        return this.myDetails.reduce((rows, detail, planDetail) => {
          const DetailRow = this._constructMyDetailRow(detail, planDetail)
          return [
            ...rows,
            DetailRow,
          ]
        }, [])
      }
      return MY_DETAILS_INITIAL_STATE
    },
    userProviderData () {
      const orderedUserProviderList = this.orderByProviderTracked(this.userProviders)
      // Filter userProviderData based on Selected View
      return orderedUserProviderList.filter(({ providerCode }) => (
        this.providerList.some(({ value }) => providerCode === value.providerCode))
      )
    },
    quickLinksData () {
      return this.filterActiveQuickLinks(this.quickLinks)
    },
    addUserProviderNotesLabel () {
      return this.activeWatchListItem?.providerNotes ? 'Edit Notes' : 'Add Notes'
    },
    trackUserProviderName () {
      return this.activeWatchListItem.isTracked === false ? 'Track Provider' : 'Untrack Provider'
    },
    activeQuickLinkNameTitle () {
      const { quickLinkName } = this.activeQuickLinkItem
      return quickLinkName && `Edit ${quickLinkName}`
    },
    activeRemoveTitle () {
      if (this.removeQuickLinkDialogOpen) {
        const { quickLinkName } = this.activeQuickLinkItem
        return quickLinkName && `Are you sure you want to remove ${quickLinkName} from the Quick Links list?`
      } else if (this.removeProviderDialogOpen) {
        const { providerName } = this.activeWatchListItem
        return providerName && `Are you sure you want to remove ${providerName} from Watch List?`
      }
      return `Are you sure you want to remove ${this.activeCellData.rowData.name} from your Details?`
    },
    disableDialogActiveBtn () {
      // Disable before select a provider in Add Provider or when inputText in Edit Quick Links, Add Provider Notes or My Details is empty
      return (this.addProviderDialogOpen && this.currentSelectedProviderId === INITIAL_SELECTED_PROVIDER_ID) ||
        (this.isEditDialogOpen && this.inputText === EMPTY_INPUT_TEXT)
    },
    myDetailsPaginationConfig () {
      return {
        pageSizes: NUMBER_OF_ROWS_PER_PAGE_OPTIONS,
        pageSize: this.tableDataPaginationData.pageSize || NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: this.tableDataPaginationData.page,
        totalItems: this.myDetails !== MY_DETAILS_INITIAL_STATE ? this.myDetails.length : 0,
        showItemsInfo: true,
        showItemsSelect: true,
      }
    },
    tableDataValidation () {
      return !this.loadingMyDetails && this.myDetails?.length > 0 && !this.errorLoadingData
    },
    emptyStateValidation () {
      return !this.loadingMyDetails && this.myDetails?.length === 0
    },
    tableDataWithPagination () {
      const { firstItem, lastItem } = this.tableDataPaginationData
      return (firstItem && lastItem)
        ? this.tableData.slice(firstItem - 1, lastItem)
        : this.tableData.slice(0, NUMBER_OF_ROWS_PER_PAGE_DEFAULT)
    },
  },
  methods: {
    async getActiveUrl () {
      // close popover before
      this.showPopover(this.$refs.popoverRef)
      // check if the link is still active before doing the redirect
      await this.$apollo.queries.myDetails.refetch()
      if (this.getActiveDetail !== null && this.getActiveDetail.isActive) {
        this.$router.push(this.getActiveDetail.urlPath)
      } else {
        this.$notifications.error({
          text: 'The link is no longer active',
          tinted: true,
          dismissDelay: 5000,
        })
      }
    },
    async changeViewAndRedirect () {
      await this.getActiveUrl()
      this.changeView(this.getActiveDetail.selectedView)
    },
    triggerModalAddProviderDialog () {
      this.$refs.popoverRef.hide()
      this.toggleDialog = true
      this.addProviderDialogOpen = true
    },
    triggerModalEditMyDetailDialog () {
      this.$refs.popoverRef.hide()
      this.toggleDialog = true
      this.editMyDetailsDialogOpen = true
    },
    triggerModalDeleteMyDetailDialog () {
      this.$refs.popoverRef.hide()
      this.toggleDialog = true
      this.removeDetailDialogOpen = true
    },
    handleTablePageChange (data) {
      this.tableDataPaginationData = data
    },
    showPopover (refPopover) {
      !refPopover.isVisible ? refPopover.show() : refPopover.hide()
    },
    closeDialog () {
      this.toggleDialog = false
      if (this.isEditDialogOpen) {
        this.inputText = EMPTY_INPUT_TEXT
        this.activeQuickLinkItem = EMPTY_ACTIVE_QUICK_LINK_ITEM
        this.activeWatchListItem = EMPTY_ACTIVE_WATCH_LIST_ITEM
        this.editQuickLinkDialogOpen = false
        this.editProviderNotesDialogOpen = false
        this.editMyDetailsDialogOpen = false
      }
      if (this.addProviderDialogOpen) {
        this.currentSelectedProviderId = INITIAL_SELECTED_PROVIDER_ID
        this.addProviderDialogOpen = false
      }
      if (this.removeQuickLinkDialogOpen || this.removeProviderDialogOpen || this.removeDetailDialogOpen) {
        this.removeQuickLinkDialogOpen = false
        this.removeProviderDialogOpen = false
        this.removeDetailDialogOpen = false
      }
    },
    actionDialogButton () {
      if (this.editQuickLinkDialogOpen === true) {
        this.editQuickLinkAction(this.activeQuickLinkItem)
      }
      if (this.editMyDetailsDialogOpen === true) {
        this.editMyDetailsAction()
      }
      if (this.removeQuickLinkDialogOpen === true) {
        this.removeQuickLinkAction(this.activeQuickLinkItem)
      }
      if (this.removeDetailDialogOpen === true) {
        this.removeMyDetailAction()
      }
      if (this.addProviderDialogOpen === true) {
        this.addProviderWatchListAction()
      }
      if (this.editProviderNotesDialogOpen === true) {
        this.editProviderNotesAction(this.activeWatchListItem)
      }
      if (this.removeProviderDialogOpen === true) {
        this.removeUserProvider(this.activeWatchListItem)
      }
    },
    toggleActionButtonPopover ({ cellData, triggerPopover }) {
      this.activeCellData = cellData
      this.triggerPopoverTable = triggerPopover
      this.showPopover(this.$refs.popoverRef)
    },
    toggleWatchListPopover ({ triggerPopover, id, providerName, providerNotes, isTracked }) {
      this.activeWatchListItem = {
        providerId: id,
        providerName,
        providerNotes,
        isTracked,
      }
      this.triggerPopoverWatchList = triggerPopover
      this.showPopover(this.$refs.popoverWatchListRef)
    },
    triggerEditQuickLinkDialog ({ linkId, quickLinkName }) {
      this.activeQuickLinkItem = {
        linkId,
        quickLinkName,
      }
      this.toggleDialog = true
      this.editQuickLinkDialogOpen = true
    },
    triggerRemoveQuickLinkDialog ({ linkId, quickLinkName }) {
      this.activeQuickLinkItem = {
        linkId,
        quickLinkName,
      }
      this.toggleDialog = true
      this.removeQuickLinkDialogOpen = true
    },
    triggerAddProviderNotesDialog () {
      this.toggleDialog = true
      this.editProviderNotesDialogOpen = true
      this.showPopover(this.$refs.popoverWatchListRef)
    },
    triggerRemoveProviderDialog () {
      this.toggleDialog = true
      this.removeProviderDialogOpen = true
      this.showPopover(this.$refs.popoverWatchListRef)
    },
    async getSelectedProviderId ({ providerId, providerCode }) {
      // Reset currentSelectedProviderId data prop immediately before executing query
      this.currentSelectedProviderId = INITIAL_SELECTED_PROVIDER_ID

      const { data } = await this.$apollo.query({
        query: GET_DASHBOARD_WATCH_LIST_PROVIDER_ID,
        variables: {
          options: {
            providerId,
            providerCode,
          },
        },
        fetchPolicy: NO_CACHE,
      })
      this.currentSelectedProviderId = data.getWatchListProviderId.id
    },
    async addProviderWatchListAction () {
      await this.$apollo.mutate({
        mutation: CREATE_DASHBOARD_USER_PROVIDER,
        variables: {
          options: {
            userId: this.userId.id,
            providerId: this.currentSelectedProviderId,
          },
        },
      }).then(() => {
        this.closeDialog()
        this.$apollo.queries.userProviders.refetch()
      })
    },
    async trackUserProvider ({ providerId, isTracked }) {
      // close popover before
      this.showPopover(this.$refs.popoverWatchListRef)
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_USER_PROVIDER_STATUS,
        variables: {
          options: {
            userId: this.userId.id,
            providerId: providerId,
            isTracked: !isTracked,
          },
        },
      }).then(({ data }) => {
        const providerUpdated = data.updateUserProviderStatus[0]
        this.userProviders.map((provider) => {
          if (provider.providerId === providerUpdated.providerId) {
            provider.isTracked = providerUpdated.isTracked
          }
        })
      })
    },
    async editProviderNotesAction ({ providerId }) {
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_USER_PROVIDER_NOTES,
        variables: {
          options: {
            userId: this.userId.id,
            providerId: providerId,
            providerNotes: this.inputText,
          },
        },
      }).then(({ data }) => {
        const providerUpdated = data.updateUserProviderNotes[0]
        this.userProviders.map((provider) => {
          if (provider.providerId === providerUpdated.providerId) {
            provider.providerNotes = providerUpdated.providerNotes
          }
        })
        this.closeDialog()
      })
    },
    async removeUserProvider ({ providerId }) {
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_USER_PROVIDER_IS_ACTIVE,
        variables: {
          options: {
            userId: this.userId.id,
            providerId,
            isActive: false,
          },
        },
      }).then(({ data }) => {
        const updatedUserProvider = data.updateUserProviderIsActive[0]
        this.userProviders.map((provider) => {
          if (provider.providerId === updatedUserProvider.providerId) {
            provider.isActive = updatedUserProvider.isActive
          }
        })
        this.closeDialog()
      })
    },
    async editQuickLinkAction ({ linkId }) {
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_QUICK_LINK_NAME,
        variables: {
          options: {
            linkId,
            quickLinkName: this.inputText,
          },
        },
      }).then(({ data }) => {
        const updatedQuickLink = data.updateQuickLinkName[0]
        this.quickLinks.map((link) => {
          if (link.linkId === updatedQuickLink.linkId) {
            link.quickLinkName = updatedQuickLink.quickLinkName
          }
        })
        this.closeDialog()
      })
    },
    async editMyDetailsAction () {
      this.toggleDialog = false
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_MY_DETAILS_NAME,
        variables: {
          options: {
            id: this.activeCellData.rowData.id,
            detailName: this.inputText,
          },
        },
      }).then(() => {
        this.closeDialog()
      })
    },
    async removeQuickLinkAction ({ linkId, quickLinkName }) {
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_QUICK_LINK_IS_ACTIVE,
        variables: {
          options: {
            linkId,
            quickLinkName,
            isActive: false,
          },
        },
      }).then(({ data }) => {
        const updatedQuickLink = data.updateQuickLinkIsActive[0]
        this.quickLinks.map((link) => {
          if (link.linkId === updatedQuickLink.linkId) {
            link.isActive = updatedQuickLink.isActive
          }
        })
        this.closeDialog()
      })
    },
    async removeMyDetailAction () {
      await this.$apollo.mutate({
        mutation: DELETE_DASHBOARD_MY_DETAILS,
        variables: {
          id: this.activeCellData.rowData.id,
        },
      }).then(() => {
        this.closeDialog()
        this.$apollo.queries.myDetails.refetch()
      })
    },
    orderByProviderTracked (providers) {
      // Filter isActive providers from Watch List and them put tracked providers at the top
      return providers.filter(item => item.isActive).sort((a, b) => a.isTracked > b.isTracked ? -1 : 1)
    },
    filterActiveQuickLinks (links) {
      return links.filter(link => link.isActive)
    },
    _constructMyDetailRow (detail, detailIndex) {
      return {
        id: detail.id,
        name: detail.detailName || '—',
        provider: detail.providerCode || '—',
        processType: detail.fileType || '—',
        fiduciary: detail.fiduciaryCode || '—',
        details: detail.detailType || '—',
      }
    },
  },
}
</script>

<style lang="scss">
  .dashboard__content {
    display: flex;
  }
  .dashboard__tables {
    width: 70%;
    padding: $mds-space-8-x;
  }
  .dashboard__quick-links-watch {
    width: 30%;
    border-left: 1px #c3c3c3 solid;

    .dashboard__quick-links {
      padding: $mds-space-3-x $mds-space-2-x;
      border-bottom: 1px #c3c3c3 solid;
      min-height: 60px;
      max-height: 250px;
      overflow-y: auto;
    }
    .dashboard__watch-list {
      padding: $mds-space-3-x $mds-space-2-x;
      overflow-y: auto;
      max-height: 70vh;
      .dashboard__btn-provider {
        padding: 0;
        color: $mds-interactive-color-primary-default;
        svg {
          stroke: $mds-interactive-color-primary-default;
        }
        &:hover {
          color: $mds-interactive-color-primary-hover;
          svg {
            stroke: $mds-interactive-color-primary-hover;
          }
        }
      }
    }
  }

  .dashboard-section {
    padding-bottom: $mds-space-4-x;
  }

  .dashboard__popover {
    display: flex;
    div {
      width: 200px;
    }
    button {
      width: 100%;
      &:nth-child(n+2) {
        border-top: 1px solid $mds-color-neutral-80;
      }
    }
  }

.dashboard__empty {
  margin: 0 auto;
  img{
    width: 100%;
  }
}
</style>

<style lang="scss" scoped>
// overlap popover over action column buttons
.mds-popover--visible___radar {
  z-index: 9999;
}

// the top must be initialized to avoid style errors
.mds-popover___radar, .mds-tooltip___radar  {
  top: 0;
}
</style>

<style lang="scss" scoped>
// overlap popover over action column buttons
.mds-popover--visible___radar {
  z-index: 9999;
}

// the top must be initialized to avoid style errors
.mds-popover___radar, .mds-tooltip___radar  {
  top: 0;
}
</style>
